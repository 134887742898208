<template>
 <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'battleInvitation'" class="w-full h-screen flex md:flex-row flex-col md:justify-center md:items-center fixed top-0 font-arial z-40 text-new-black">

      <!-- Components -->
      <preQueryQuestions />
      <queryQuestions />
      <!-- Components -->

      <div class="md:absolute w-full h-full md:flex md:flex-row md:justify-center md:items-center" @click="closeTrainer">
        <div class="absolute md:static bottom-0 w-full md:w-auto md:max-w-80 h-auto md:h-auto bg-white rounded-t-3xl md:rounded-3xl p-5 md:p-12">

          <div class="mt-3 md:mt-0 font-faro font-bold text-3xl text-center">Battleanfrage</div>
          <div class="mt-5 text-center">Du hast eine Battleanfrage von {{ battle?.attributes?.creator?.data?.attributes?.nickname }} bekommen. Möchtest du das Battle starten?</div>

          <div class="w-full mt-5">
            <customButton label='Anfrage annehmen' :info="`(${rounds} Runden á 4 Fragen - ${questions.length} Fragen insgesamt)`" :loading="questions.length === 0 || isFetching" @click="$emit('initBattle')"/>
          </div>

          <div class="w-full mt-3">
            <customButton label='Anfrage ablehnen' @click="$emit('declineBattle')"/>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
// Strapi
import preQueryQuestions from '../strapi/queries/preQueryQuestions.vue';
import queryQuestions from '../strapi/queries/queryQuestions.vue';

// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import detectNotch from '../../../mixins/detectNotch.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import customButton from '../../../symbols/customButton.vue';

export default {
  name: 'battleInvitation',
  props: ['battle'],
  mixins: [slideInOutBottom, detectNotch, iosVibrations],
  components: {
    preQueryQuestions, queryQuestions, customButton,
  },

  computed: {
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    isFetching() {
      if (this.$store.state.training.isFetchingPreQuestions || this.$store.state.training.isFetchingQuestions) {
        return true;
      }
      return false;
    },
    questions() {
      return this.$store.state.training.currentTraining.questionIds;
    },
    rounds() {
      return this.questions.length / 4;
    },
  },

  methods: {
    closeTrainer() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },
  },
};
</script>
