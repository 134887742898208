<template>
<infoOverlay text="Wähle eine andere Filterkombination, um mindestens 4 Fragen zu erhalten." :index=11 :level=2 />
<infoOverlay text="Dein Battle wurde veröffentlicht. Sobald jemand dem Battle beitritt bekommst du eine Benachrichtigung." button="Zurück zur Übersicht" :index=12 :level=2 @buttonClicked="closeTrainer" />
<infoOverlay v-if="settings?.battlePlayerName" :text="`Deine Battleanfrage wurde verschickt. Sobald ${settings.battlePlayerName} deine Anfrage angenommen hat bekommst du eine Benachrichtigung.`" button="Zurück zur Übersicht" :index=13 :level=2 @buttonClicked="closeTrainer" />

 <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showOverlay === 'battleTrainer'" class="w-full h-auto md:h-full fixed top-0 md:flex md:flex-row md:justify-center md:items-center md:inset-0 z-30 font-arial text-new-black pointer-events-none">
      <div class="relative w-full md:w-5/12 md:min-w-100 h-screen min-h-screen md:min-h-0 md:h-auto md:max-h-80  md:overflow-y-auto md:bg-new-yellow md:rounded-t-3xl md:rounded-3xl md:p-5 pointer-events-auto scrollbar flex flex-col">

        <!-- Components -->
        <darkBackground2 />
        <subjectPicker :all="true" />
        <querySubjects />
        <tagPicker />
        <examPicker />
        <preQueryQuestions />
        <queryMcExams />
        <queryQuestions />
        <playerPicker />
        <queryBattlePartners />
        <queryPublicBattles />
        <battlePicker />
        <createNewBattleMutation />
        <sendBattleInviteMutation />
        <!-- Components -->

        <!-- Content -->
        <div class="w-full h-1/6 flex-shrink-0" @click="closeTrainer"></div>
        <div class="w-full flex-grow bg-new-yellow rounded-t-3xl p-5 overflow-y-scroll scrollbar">

          <!-- Heading -->
          <div class="md:hidden text-xl cursor-pointer" @click="closeTrainer">schließen</div>
          <div class="mt-3 md:mt-0 font-faro font-bold text-3xl">Neues Battle</div>

          <!-- Options Block -->
          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey">
            <setting :greyed="publicBattles?.length === 0 ? true : false" label='Öffentliches Battle' showicon=true icon='lightning' action='chevron' :border="true" :additionalText="settings.publicBattleId?  'Battle von ' + settings.battlePlayerName  : 'wähle ein öffentliches Battle'" @click="openPicker('battlePicker')" />
            <setting :greyed="this.settings?.publicBattleId ? true : false" label='Gegenspieler' showicon=true icon='battle' action='chevron' :additionalText="settings.battlePlayerName || 'Optional, wähle einen Gegenspieler'" @click="openPicker('playerPicker')" />
          </div>

          <!-- Filter Block -->
          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey">
            <setting :greyed="this.settings.publicBattleId ? true : false" label='Filter nach Fächern' showicon=true icon='faecher' :border=true action='chevron' truncate=true :additionalText="formatText(settings?.subjects) || 'Optional'" @click="openPicker('subjectPicker')" />
            <setting v-if="exams" :greyed="this.settings.publicBattleId ? true : false" label='Filter nach Prüfungen' showicon=true icon='exam' action='chevron' :border="settings?.subjects.length > 0 && preTags?.length > 0" :additionalText="formatText(settings?.exams) || 'Optional'" @click="openPicker('examPicker')" />
            <setting v-if="settings?.subjects.length > 0 && preTags?.length > 0" :greyed="this.settings.publicBattleId ? true : false" label='Filter nach Unterthemen' showicon=true icon='tag' action='chevron' :additionalText="formatText(settings?.tags) || 'Optional'" @click="openPicker('tagPicker')" />
          </div>

          <!-- Options Block -->
          <div class="w-full bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey">
            <setting :deactivated="settings.publicBattleId ? true : false" :greyed="this.settings.publicBattleId ? true : false" label='Begrenzte Anzahl Fragen' showicon=true icon='chat' action='toggle' additionalAcion='numInput' addActionLabel='Fragen:' :addActionDefault="20" :loadedValue="settings?.limitFilter?.limit" addActionStoreID='questions' storeID='limitedQuestions' storeContainer='training' storeWrapper='settings' />
          </div>

        </div>
        <!-- Content -->

        <!-- Button -->
        <div class="flex-shrink-0 pb-5 min-h-28 w-full p-5 bg-white md:bg-new-yellow shadow-top md:shadow-none md:border-t-2 md:border-hi-lighgrey">
          <customButton v-if="settings.publicBattleId" label='Battle starten' :info="`(${questions.length} Fragen)`" :loading="isFetching" @click="initBattle(settings.publicBattleId)"/>
          <customButton v-else-if="settings.battlePlayerName" label='Battle Anfrage verschicken' :info="`(${questions.length} Fragen)`" :loading="isFetching" @click="requestBattle"/>
          <customButton v-else label='Battle veröffentlichen' :info="`(${questions.length} Fragen)`" :loading="isFetching" @click="publishBattle" />
        </div>
        <!-- Button -->

      </div>
    </div>
  </transition>
</template>

<script>
// Strapi
import preQueryQuestions from '../strapi/queries/preQueryQuestions.vue';
import queryQuestions from '../strapi/queries/queryQuestions.vue';
import queryBattlePartners from '../strapi/queries/queryBattlePartners.vue';
import createNewBattleMutation from '../strapi/mutations/createNewBattleMutation.vue';
import sendBattleInviteMutation from '../strapi/mutations/sendBattleInviteMutation.vue';
import queryPublicBattles from '../strapi/queries/queryPublicBattles.vue';
import queryMcExams from '../strapi/queries/queryMcExams.vue';
import querySubjects from '../strapi/queries/querySubjects.vue';

// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import detectNotch from '../../../mixins/detectNotch.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';
import battleFunctions from '../../../mixins/battleFunctions.vue';

// Components
import infoOverlay from '../../../symbols/infoOverlay.vue';
import darkBackground2 from '../../../symbols/darkBackground2.vue';
import setting from '../../../symbols/setting.vue';
import customButton from '../../../symbols/customButton.vue';
import subjectPicker from '../components/subjectPicker.vue';
import tagPicker from '../components/tagPicker.vue';
import examPicker from '../components/examPicker.vue';
import playerPicker from '../components/playerPicker.vue';
import battlePicker from '../components/battlePicker.vue';

export default {
  name: 'battleTrainer',
  mixins: [slideInOutBottom, detectNotch, iosVibrations, battleFunctions],
  components: {
    preQueryQuestions,
    querySubjects,
    queryQuestions,
    darkBackground2,
    setting,
    customButton,
    subjectPicker,
    tagPicker,
    examPicker,
    playerPicker,
    queryBattlePartners,
    battlePicker,
    createNewBattleMutation,
    infoOverlay,
    sendBattleInviteMutation,
    queryPublicBattles,
    queryMcExams,
  },

  computed: {
    createNewBattle() {
      return this.$store.state.createNewBattle;
    },
    sendBattleInvite() {
      return this.$store.state.sendBattleInvite;
    },
    showOverlay() {
      return this.$store.state.layout.showOverlay;
    },
    me() {
      return this.$store.state.profile.me;
    },
    userDataId() {
      return this.me?.userData?.data?.id;
    },
    settings() {
      return this.$store.state.training?.settings;
    },
    currentTraining() {
      return this.$store.state.training?.currentTraining;
    },
    currentCourseIndex() {
      return this.$store.state.settings.currentCourseIndex;
    },
    currentCourseId() {
      return this.$store.state.settings.currentCourseID;
    },
    exams() {
      return this.$store.state.training.mcExams;
    },
    preTags() {
      return this.$store.state.training.preTags;
    },
    isFetching() {
      if (this.$store.state.training.isFetchingPreQuestions || this.$store.state.training.isFetchingQuestions) {
        return true;
      }
      return false;
    },
    questions() {
      return this.$store.state.training.currentTraining.questionIds;
    },
    queryMyBattlesEx() {
      return this.$store.state.queryMyBattlesEx;
    },
    publicBattles() {
      return this.$store.state.training.publicBattles;
    },
  },

  methods: {
    closeTrainer() {
      this.iosVibrate(0);
      this.$store.commit('showOverlay', null);
    },

    formatText(array) {
      return array.join(', ');
    },

    openPicker(value) {
      if (value === 'battlePicker') {
        if (this.publicBattles?.length > 0) {
          this.iosVibrate(0);
          this.$store.commit('showSecondOverlay', value);
        }
      } else if (!this.settings.publicBattleId) {
        this.iosVibrate(0);
        this.$store.commit('showSecondOverlay', value);
      }
    },

    requestBattle() {
      if (!this.isFetching) {
        if (this.questions.length < 4) {
          this.iosVibrate(1);
          this.$store.commit('toggleInfoOverlay', { value: true, index: 11, level: 2 });
        } else {
          this.iosVibrate(1);
          // Create private battle
          this.createNewBattle({
            creator: this.userDataId,
            opponent: this.settings.battlePlayerId,
            course: this.currentCourseId,
            settings: this.settings,
            public: false,
          }).then((result) => {
            if (result.error) {
              console.log(result.error.message);
            } else {
              // then send invite
              this.sendBattleInvite({ opponentUserDataId: this.settings.battlePlayerId, courseId: this.currentCourseId });
              this.queryMyBattlesEx();
              this.$store.commit('toggleInfoOverlay', { value: true, index: 13, level: 2 });
            }
          });
        }
      }
    },

    publishBattle() {
      this.createNewBattle({
        creator: this.userDataId,
        course: this.currentCourseId,
        settings: this.settings,
        public: true,
      }).then((result) => {
        if (result.error) {
          console.log(result.error.message);
        } else {
          this.queryMyBattlesEx();
          this.$store.commit('toggleInfoOverlay', { value: true, index: 12, level: 2 });
        }
      });
    },
  },
};
</script>
