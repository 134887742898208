<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'subjectPicker'" class="fixed md:absolute top-0 left-0 w-full h-screen md:h-full z-50 flex flex-row justify-center items-center pointer-events-none text-new-black font-arial">
      <div class="w-full h-full absolute bottom-0 pointer-events-auto">


        <!-- Content -->
        <div class="w-full h-1/6" @click="hidePicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">
          <!-- Fixed -->
          <div class="h-auto">
            <div class="text-xl" @click="hidePicker">schließen</div>
            <div class="mt-2 font-faro font-bold text-3xl">Filter nach Fächern</div>
          </div>
          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">
          <div v-if="subjects && !all" class="w-full md:auto bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey overflow-hidden">
            <div v-for="(lernpfad, index) in subjects" :key="lernpfad">
              <selector
                :label=lernpfad?.Lernpfad?.data?.attributes?.Bezeichnung
                storeContainer='training'
                storeWrapper='settings'
                storeID='subjects'
                storeID2='subjectsID'
                :value2=lernpfad?.Lernpfad?.data?.id
                :border="index < subjects.length -1 ? true : false" />
            </div>
          </div>
          <div v-else-if="mcSubjects && all" class="w-full md:auto bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey overflow-hidden">
            <div v-for="(lernpfad, index) in mcSubjects" :key="lernpfad">
              <selector
                :label=lernpfad?.attributes?.Bezeichnung
                storeContainer='training'
                storeWrapper='settings'
                storeID='subjects'
                storeID2='subjectsID'
                :value2=lernpfad?.id
                :border="index < subjects.length -1 ? true : false" />
            </div>
          </div>
          </div>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>

// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import selector from '../../../symbols/selector.vue';

export default {
  name: 'subjectPicker',
  props: ['all'],
  mixins: [slideInOutBottom, iosVibrations],
  components: { selector },

  computed: {
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    me() {
      return this.$store.state.profile.me;
    },
    currentCourseIndex() {
      return this.$store.state.settings.currentCourseIndex;
    },
    mcSubjects() {
      return this.$store.state.training.mcSubjects;
    },
    currentSemester() {
      return this.$store.state.settings.currentSemester;
    },
    subjects() {
      const klasse = this.me?.klassen?.data[this.currentCourseIndex]?.attributes.Zuordnung;
      const index = klasse.findIndex((zuordnung) => zuordnung.Kurs);
      const lernpfade = klasse[index]?.Lernpfade;
      const filteredLernpfade = [];
      lernpfade.forEach((lernpfad) => {
        if (lernpfad.Semester <= this.currentSemester) filteredLernpfade.push(lernpfad);
      });
      return filteredLernpfade;
      // return klasse[index]?.Kurs?.data?.attributes?.lernpfade?.data;
    },
  },

  methods: {
    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', null);
    },
  },
};
</script>

