<template>
  <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'playerPicker'" class="fixed md:absolute top-0 left-0 w-full h-screen md:h-full z-50 flex flex-row justify-center items-center pointer-events-none text-new-black font-arial">
      <div class="w-full h-full absolute bottom-0 pointer-events-auto">

        <!-- Content -->
        <div class="w-full h-1/6" @click="hidePicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">
          <!-- Fixed -->
          <div class="h-auto pb-5">
            <div class="text-xl" @click="hidePicker">schließen</div>
            <div class="mt-2 font-faro font-bold text-3xl">Wähle deinen Gegenspieler</div>
            <searchBar class="h-16 w-full mt-5 border border-hi-lighgrey"/>
          </div>
          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">
          <div v-if="filteredBattlePartners" class="w-full md:auto bg-white rounded-xl shadow-new  border border-hi-lighgrey overflow-hidden">
             <div v-for="(partner, index) in filteredBattlePartners" :key="partner">
              <div class="w-full p-3 flex flex-row justify-between items-center font-arial cursor-pointer" :class="{ 'border-b border-new-yellow' : index < battlePartners.length -1, 'bg-new-black' : selectedPlayer === index}" @click="togglePlayer(index, partner.attributes.nickname, partner.id)">
                <div  class="relative w-10 h-10 rounded-full bg-white mr-5 flex flex-row justify-center items-center border-2 border-hi-lighgrey overflow-hidden">
                  <div v-if="partner?.attributes?.avatar?.data" class="w-full h-full bg-center bg-cover bg-no-repeat" :style="{ backgroundImage: 'url(' + partner?.attributes?.avatar?.data?.attributes?.url + ')' }"></div>
                  <profileSVG v-else />
                </div>
                <div class="flex-grow pr-2 flex flex-row justify-between items-center">
                  <div class="text-lg" :class="{'text-white' : selectedPlayer === index}">{{ partner.attributes.nickname }}</div>
                  <checkSVG v-if="selectedPlayer === index" />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// TODO: Empty states, tranisitions on click
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// SVG
import profileSVG from '../../../assets/svg/profile.vue';
import checkSVG from '../../../assets/svg/check.vue';

// Components
import searchBar from '../../navbar/components/searchBar.vue';


export default {
  name: 'playerPicker',
  mixins: [slideInOutBottom, iosVibrations],
  components: { profileSVG, checkSVG, searchBar },

  data() {
    return {
      selectedPlayer: null,
    };
  },

  computed: {
    searchString() {
      return this.$store.state.layout.searchString;
    },
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    battlePartners() {
      return this.$store.state.training.battlePartners;
    },
    myUserDataId() {
      return this.$store.state.profile.me?.userData?.data?.id;
    },
    filteredBattlePartners() {
      // filter myself & empty
      const filteredResult = [];
      this.battlePartners.forEach((partner) => {
        if (partner.id !== this.myUserDataId && partner.attributes.nickname !== null) {
          filteredResult.push(partner);
        }
      });
      // filter by searchBar
      let searchFilteredResult = [];
      if (this.searchString !== '') {
        filteredResult.forEach((partner) => {
          if (partner.attributes.nickname.toLowerCase().includes(this.searchString.toLowerCase())) {
            searchFilteredResult.push(partner);
          }
        });
      } else {
        searchFilteredResult = filteredResult;
      }
      return searchFilteredResult;
    },
  },

  methods: {

    togglePlayer(index, name, id) {
      if (this.selectedPlayer === index) {
        this.selectedPlayer = null;
        this.$store.commit('addSelector', {
          storeContainer: 'training', storeWrapper: 'settings', storeID: 'battlePlayerId', value: id, type: 'replace',
        });
        this.$store.commit('addSelector', {
          storeContainer: 'training', storeWrapper: 'settings', storeID: 'battlePlayerName', value: null, type: 'replace',
        });
      } else {
        this.selectedPlayer = index;
        this.$store.commit('addSelector', {
          storeContainer: 'training', storeWrapper: 'settings', storeID: 'battlePlayerId', value: id, type: 'replace',
        });
        this.$store.commit('addSelector', {
          storeContainer: 'training', storeWrapper: 'settings', storeID: 'battlePlayerName', value: name, type: 'replace',
        });
      }
    },

    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', null);
    },
  },
};
</script>
