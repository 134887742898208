<template>
   <transition @enter="slideInBottom" @leave="slideOutBottom" :css="false" mode="out-in">
    <div v-if="showSecondOverlay === 'listSettingPicker'" class="fixed md:absolute top-0 left-0 w-full h-screen md:h-full z-50 flex flex-row justify-center items-center pointer-events-none text-new-black font-arial">
      <div class="w-full h-full absolute bottom-0 pointer-events-auto">

        <!-- Content -->
        <div class="w-full h-1/6" @click="hidePicker"></div>
        <div class="w-full h-5/6 bg-new-yellow rounded-t-3xl md:rounded-3xl p-5 flex flex-col">
          <!-- Fixed -->
          <div class="h-auto">
            <div class="text-xl" @click="hidePicker">schließen</div>
            <div class="mt-2 font-faro font-bold text-3xl">Filter nach eigenen Listen</div>
          </div>
          <!-- Scrollable -->
          <div class="flex-grow overflow-y-scroll scrollbar">
          <div v-if="myLists" class="w-full md:auto bg-white rounded-xl shadow-new mt-5 border border-hi-lighgrey overflow-hidden">
            <div v-for="(list, index) in myLists" :key="list">
              <selector
                :label=list.Bezeichnung
                storeContainer='training'
                storeWrapper='settings'
                storeID='lists'
                storeID2='listsID'
                :value2=list[listType].ids
                :isArray=true
                :border="index < myLists.length -1 ? true : false" />
            </div>
          </div>
          </div>
        </div>
        <!-- Content -->


      </div>
    </div>
  </transition>
</template>

<script>
// Mixins
import slideInOutBottom from '../../../mixins/animations/slideInOutBottom.vue';
import iosVibrations from '../../../mixins/iosVibrations.vue';

// Components
import selector from '../../../symbols/selector.vue';

export default {
  name: 'listSettingPicker',
  mixins: [slideInOutBottom, iosVibrations],
  components: { selector },
  props: ['listType'],

  computed: {
    showSecondOverlay() {
      return this.$store.state.layout.showSecondOverlay;
    },
    questions() {
      return this.$store.state.training.questions;
    },
    questionId() {
      return this.questions?.mcFragens?.data[this.current]?.id;
    },
    myLists() {
      return this.$store.state?.profile?.me?.userData?.data?.attributes?.myLists;
    },
  },

  methods: {
    hidePicker() {
      this.iosVibrate(0);
      this.$store.commit('showSecondOverlay', null);
    },
  },
};
</script>
